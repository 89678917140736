import * as Text from "@/components/text";
import React, { useState, useEffect } from "react";
import SocialHead from "@/components/SEO/SocialHead";
import Link from "next/link";
import Modal from "@/components/lib/Modal";
import CallToAction from "@/components/catalog/CallToAction";
import dynamic from "next/dynamic";
import qs from "qs";

import HomeSlider from "@/components/content/HomeSlider";
import InitiativeCard from "@/components/banners/InitiativeCard";
import TourBanner from "@/components/banners/TourBanner";
import {
  Featured,
  VerticalCard,
  HorizontalCard,
} from "@/components/publications/Cards";
import CardsGallery from "@/components/publications/CardsGallery";
import { Nav } from "@/components/navigation/Navbar";
import { getStrapiImage, getStrapiFileType, getStrapiMimetype } from "@/utils/strapiFunctions";

import useWindowSize from "@/hooks/useWindowSize";
import breakpoints from "@/constants/breakpoinst";
import apiClient from "@/utils/apiClient";

import { useRouter } from "next/router";
import { localeTexts} from '@/utils/locale';


const ExploreMovies = dynamic(
  () => import("@/components/content/ExploreMovies"),
  {
    loading: () => <>Cargando...</>,
  }
);

export default function Home({ movieImages, movieSlides, home, locale }) {
  let [isOpen, setIsOpen] = useState(home?.popup?.active);
  const windowSize = useWindowSize();
  const [homeData, setHomeData] = React.useState({...home});
  
  useEffect(() => {
    if (locale === "en" && home.localizations?.data?.length > 0) {
      // Find English localization
      const englishData = home.localizations.data.find(
        localization => localization.attributes.locale === "en"
      );
      
      if (englishData) {
        setHomeData({
          ...home, // Original attributes
          ...englishData.attributes // Override with English content
        });
      }
    } else {
      // Reset to original data
      setHomeData({...home});
    }
  }, [locale, home]);

  return (
    
    <div>
      <SocialHead
        title="Ambulante - Gira de documentales"
        description="Gira de documentales"
      />
      {/* <SearchBar display={showSearch} setDisplay={toggleSearch} /> */}
      <div className="relative w-full">
        <div className="absolute top-0 z-30 inline-flex w-full m-auto ">
          <div className="w-full ">
            <div className="amb-container">
              <Nav className="bg-gray-100 grow" theme="dark" />
            </div>
          </div>
        </div>
        <HomeSlider slides={movieSlides} />

        {/* Noticias */}
        <div className="section xl:p-0 ">
          <div className="mb-14 lg:flex lg:flex-wrap">
            <div className="w-full">
              <Text.Display as="h3">{homeData.ahora_ambulante?.titulo}</Text.Display>
            </div>
            <div className="mr-[9px] lg:w-full">
              <Text.Body size="xl">
                {homeData.ahora_ambulante.descripcion}
              </Text.Body>
            </div>
          </div>
          {home.tour_banner.active ? (
            <TourBanner
              logo={getStrapiImage(home.tour_banner.tour_logo)}
              title={homeData.tour_banner.title}
              description={homeData.tour_banner.description}
              image={getStrapiImage(home.tour_banner.background)}
              countdown={home?.tour_banner?.countdown}
            />
          ) : null}
          {windowSize.width >= breakpoints.lg ? (
            <>
              {homeData?.ahora_ambulante?.primary_entries?.data.length ? <div className="flex gap-5 ">
                <div className="-mr-[9px] min-h-[350px] w-2/3 lg:h-[490px] lg:w-1/2">
                  {homeData?.ahora_ambulante?.primary_entries?.data[0] ? <Featured
                    metadata={{
                      date: new Date(
                        home?.ahora_ambulante?.primary_entries?.data[0].attributes?.publishedAt
                      ),
                      type: home?.ahora_ambulante?.primary_entries?.data[0].attributes?.entry_type,
                      length: "",
                    }}
                    description={
                      homeData?.ahora_ambulante?.primary_entries?.data[0]
                        ?.attributes?.contenido
                    }
                    title={
                      homeData?.ahora_ambulante?.primary_entries?.data[0].attributes
                        .titulo
                    }
                    image={
                      home?.ahora_ambulante?.primary_entries?.data[0].attributes
                        .cover_image.data?.attributes?.url
                    }
                    link={`/blog/${home?.ahora_ambulante?.primary_entries?.data[0].attributes.slug}`}
                  /> : null}
                </div>
                {homeData?.ahora_ambulante?.primary_entries?.data[1] ? (
                  <div className="min-h-[350px] w-1/3 pl-[9px] lg:h-[490px] lg:w-1/2 lg:pl-0">
                    <Featured
                      metadata={{
                        date: new Date(
                          home?.ahora_ambulante?.primary_entries?.data[1].attributes?.publishedAt
                        ),
                        type: home?.ahora_ambulante?.primary_entries?.data[1].attributes?.entry_type,
                        length: "",
                      }}
                      description={
                        homeData?.ahora_ambulante?.primary_entries?.data[1]
                          ?.attributes?.contenido
                      }
                      title={
                        homeData?.ahora_ambulante?.primary_entries?.data[1]
                          .attributes.titulo
                      }
                      image={
                        homeData?.ahora_ambulante?.primary_entries?.data[1]
                          .attributes.cover_image.data?.attributes?.url
                      }
                      aspect="square"
                      link={`/blog/${home?.ahora_ambulante?.primary_entries?.data[1].attributes.slug}`}
                    />
                  </div>
                ) : null}
              </div> : null}
            </>
          ) : (
            <>
              <CardsGallery
                slidesPerView={windowSize.width >= breakpoints.md ? 2.5 : 1.5}
                spaceBetween={windowSize.width >= breakpoints.md ? 20 : 15}
                slides={homeData?.ahora_ambulante?.primary_entries?.data}
              />
            </>
          )}

          <div className="gap-5 pt-12 md:grid md:grid-cols-2 md:pt-16 lg:grid-cols-4 lg:pt-0">
            {windowSize.width >= breakpoints.lg ? (
              <>
                {homeData?.ahora_ambulante?.secondary_entries?.data
                  .slice(0, 4)
                  .map((post) => (
                    <VerticalCard
                      key={post.id}
                      metadata={{
                        date: new Date(post.attributes.publishedAt),
                        type: post.attributes.entry_type,
                        length: "",
                      }}
                      description={post.attributes.contenido}
                      title={post.attributes.titulo}
                      image={
                        post.attributes.cover_image.data?.attributes?.formats
                          ?.medium?.url
                      }
                      link={`/blog/${post.attributes.slug}`}
                      orientation="landscape"
                    />
                  ))}
              </>
            ) : (
              <>
                {homeData?.ahora_ambulante?.secondary_entries?.data
                  .slice(0, 4)
                  .map((post) => (
                    <HorizontalCard
                      key={post.id}
                      metadata={{
                        date: new Date(post.attributes.publishedAt),
                        type: post.attributes.entry_type,
                        length: "",
                      }}
                      description={post.attributes.contenido}
                      title={post.attributes.titulo}
                      image={
                        post.attributes.cover_image.data?.attributes?.formats
                          ?.medium?.url
                      }
                      link={`/blog/${post.attributes.slug}`}
                    />
                  ))}
              </>
            )}
          </div>
        </div>
        <hr className="bt-2 border-neutral-1000"></hr>
        <div className="section xl:p-0">
          <div className="mb-14 lg:flex lg:flex-wrap">
            <div className="w-full">
              <Text.Display as="h3">{localeTexts[locale].nuestrosP}</Text.Display>
            </div>
            <div className="mr-[9px] lg:w-full">
              <Text.Body size="xl">
                {localeTexts[locale].mision}
              </Text.Body>
            </div>
          </div>

          <div className="gap-5 space-y-5 md:flex md:space-y-0">
            <InitiativeCard className="w-1/4" inititative="gira-ambulante"  currentLocale={locale}/>
            <InitiativeCard
              className="w-1/4"
              inititative="ambulante-presenta"
              currentLocale={locale}
            />
            <InitiativeCard
              className="w-1/4"
              inititative="ambulante-mas-alla"
              currentLocale={locale}
            />

            <InitiativeCard className="w-1/4" inititative="ediciones" currentLocale={locale}/>
          </div>
        </div>
        <hr className="bt-2 border-neutral-1000"></hr>
        <div className="section">
          <ExploreMovies
            title={localeTexts[locale].expMT}
            subtitle={localeTexts[locale].expMSub}
            button_label={localeTexts[locale].btnExpC}
            images={movieImages}
            button_link="/catalogo"
          />
        </div>
      </div>

      {/* POPUP */}
      {home.popup.active ? (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
          <div className="flex flex-col items-center justify-center">
            <Link href={home.popup.url} target="_blank">
              <img className="" src={getStrapiImage(home.popup.image)} alt="popup" />
            </Link>
          </div>
        </Modal>
      ) : null}


    </div>
  );
}

export async function getServerSideProps(context) {
  try {
    const {locale} = context.query;
    const query = qs.stringify(
      {
        populate: {
          localizations:"*",
          slides: {
            populate: ["background_image"],
          },
          ahora_ambulante: {
            populate: {
              localizations: "*",
              primary_entries: {
                populate: "*",
                publicationState: "live",
              },
              secondary_entries: {
                populate: "*",
                publicationState: "live",
              },
            },
          },
          tour_banner: {
            populate: {
              tour_logo: {
                populate: "*",
              },
              background: {
                populate: "*",
              },
            },
          },
          popup: {
            populate: "*",
          },
          mosaico: {
            populate: {
              peliculas: {
                populate: {
                  film_cover_image: "*",
                },
              },
            },
          },
        },
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    );
    
    const home = await apiClient.get(`/api/home?${query}`);

    const mosaicImages =
      home?.data?.data?.attributes?.mosaico?.peliculas?.data.map((m) =>
        getStrapiImage(m?.attributes?.film_cover_image)
      );

    const slides = home?.data?.data?.attributes?.slides;

    // generate movieslides from slides
    const movieSlides = slides.map((s) => {
      const fileType = getStrapiFileType(s?.background_image) || "";
      return {
        big: {
          head: s.header_text,
          title: s.title,
          subtitle: s.subtitle,
          description: s.description,
          fileType: getStrapiFileType(s?.background_image) || "",
          mime: getStrapiMimetype(s?.background_image) || "",
          image: getStrapiImage(s?.background_image) || "",
          link: s.link,
          link_label: s.link_label,
        },
        small: {
          head: s.header_text,
          title: s.title,
          subtitle: s.subtitle,
          description: s.description,
          thumbImage: fileType === 'image' ? getStrapiImage(s?.background_image) : "",
        },
      };
    });

    const q = qs.stringify({
      populate: "*",
    });

    return {
      props: {
        home: home?.data.data.attributes,
        movieImages: mosaicImages,
        movieSlides,
        locale: locale || "es-MX"
      },
    };
  } catch (err) {    
    return {
      notFound: true,
    };
  }
}
